import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MobileApp from "../components/mobile-app";
import SeO from "../components/SEO";

export default function mobileApp() {
  return (
    <div>
      <SeO title={`Mobile App`} defer={true} />
      <Header />
      <MobileApp showBoth />
      <Footer />
    </div>
  );
}
